import { Navigate, Route, Routes } from 'react-router-dom';
import './styles/main.scss';
import Main from './views/Main';
import Incidents from './views/Surveys/Incidents';
import SurveyDone from './views/Surveys/SurveyDone';
import SendBudget from './views/SendBudget';
import CreateIncidents from './views/CrmViews/CreateIncidents';
import { ToastContainer } from 'react-toastify';
import CrmActionDone from './views/CrmViews/CrmActionDone';
import ProviderShipmentInfo from './views/CrmViews/ProviderShipmentInfo';
import PartProblem from './views/CrmViews/PartProblem';
import OfflineRequestResponse from './views/CrmViews/OfflineRequestResponse';
import ReconstruidosRequestResponse from './views/CrmViews/ReconstruidoRequestResponse';
import IntegracionesRequestResponse from './views/CrmViews/IntegracionesRequestResponse';
import PartConfirm from './views/CrmViews/PartConfirm';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

function App () {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title id="pageTitle">{ t('header.title') }</title>
                <link rel="canonical" href="https://www.recomotor.com" />

                <meta name="viewport" content="width=device-width, maximum-scale=5.0" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.recomotor.com" />
                <meta property="og:site_name" content="Recomotor" />
                <meta name="format-detection" content="telephone=no, date=no, email=no, address=no" />
                <meta name="description" id="metaDescription" content={ t('header.meta.description') } />
                <meta property="og:title" id="ogTitle" content={ t('header.meta.og_title') } />
                <meta property="og:description" id="ogDescription" content={ t('header.meta.og_description') } />
                <meta property="og:locale" id="ogLocale" content="es_ES" />

                <script type="application/ld+json">
                    {`
                        {
                        "@context": "https://schema.org",
                        "@type": "OnlineStore",
                        "url": "https://www.recomotor.com/",
                        "sameAs": [
                            "https://www.linkedin.com/company/recomotor",
                            "https://www.instagram.com/recomotor/"
                        ],
                        "logo": "https://www.recomotor.com/public/recomotor-logo.png",
                        "legalName": "JG RECOMOTOR S.L.",
                        "name": "Recomotor",
                        "description": ${t('header.meta.description')},
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "Polígono dels Frares, Calle D, parcela número 46",
                            "addressLocality": "Lleida",
                            "postalCode": "25190",
                            "addressCountry": "ES"
                        },
                        "vatID": "B04950093",
                        "email": "info@recomotor.com",
                        "telephone": "+34 973 93 93 92",
                        "foundingDate": "2021-04-29"
                        }
                    `}
                </script>
            </Helmet>

            <Routes>
                <Route path='*' exact={true} element={<Main />}/>
                <Route path='*' exact={true} element={<Navigate to="/" />}/>
                <Route path='/incidencias/:incidenciaId/encuesta' element={<Incidents />} />
                <Route path='/incidencias-vo/:incidenciaId/encuesta' element={<Incidents comesFrom='vo' />} />
                <Route path='/encuesta/finalizada' element={<SurveyDone />} />
                <Route path='/presupuesto/:checkoutId' element={<SendBudget />} />
                <Route path='/gracias' element={<CrmActionDone />} />
                <Route path='/solicitudes/:ventaId/incidencia' element={<CreateIncidents />} />
                <Route path='/solicitudes-vo/:ventaId/incidencia' element={<CreateIncidents comesFrom='vo' />} />

                <Route path='/integracion/:ventaId/problema/:proveedorId' element={<PartProblem />} />
                <Route path='/integracion-vo/:ventaId/problema/:proveedorId' element={<PartProblem comesFrom='vo' />} />
                <Route path='/integracion/:ventaId/confirmacion/:proveedorId' element={<PartConfirm />} />
                <Route path='/integracion-vo/:ventaId/confirmacion/:proveedorId' element={<PartConfirm comesFrom='vo' />} />

                <Route path='/integracion/respuesta_solicitud' element={<IntegracionesRequestResponse />} />
                <Route path='/venta-online/integracion/respuesta_solicitud' element={<IntegracionesRequestResponse comesFrom='vo' />} />

                <Route path='/proveedores/:proveedorId/:ventaId/informacion' element={<ProviderShipmentInfo />} />
                <Route path='/desguaces_offline/respuesta_solicitud' element={<OfflineRequestResponse />} />
                <Route path='/reconstruidos/respuesta_solicitud' element={<ReconstruidosRequestResponse />} />
            </Routes>

            <ToastContainer
                containerId="dashboard"
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
            />
        </>
    );
}

export default App;
